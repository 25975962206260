import React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, IconButton, Typography } from '@mui/material'
import { toast } from 'react-toastify'
import CloseIcon from '@mui/icons-material/Close'
import { CitiesSelect } from './cities-select'
import { BookingTypeGroupButton } from './booking-type-group-button'
import { createPartner } from '../api'
import { usePartnerFormState } from '../stores'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export function BecomePartnerDialog(props: Props) {
  const { formState, setFormState, reset } = usePartnerFormState()
  const handleFieldChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      [ev.target.name]: ev.target.value,
    })
  }

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      const newPartner = {
        name: formState.name,
        email: formState.email,
        tel: formState.tel,
        city: JSON.stringify(formState.city),
        type: formState.type,
      }
      await createPartner(newPartner)
      reset()
      props.onClose()
      toast.success('Inscription réussie! Nous vous contacterons bientôt.', {
        position: 'top-center',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'colored',
      })
    } catch (error) {
      console.error('Failed to create partner:', error)
    }
  }

  return (
    <Dialog open={props.isOpen} onClose={props.onClose} scroll="body">
      <IconButton
        onClick={props.onClose}
        sx={{
          position: 'absolute',
          top: 4,
          right: 8,
        }}
      >
        <CloseIcon sx={{ fontSize: 26 }} />
      </IconButton>
      <DialogTitle>Devenir un partenaire</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1">
              Vous avez une entreprise de lavage de voitures? Devenez notre
              partenaire, augmentez votre visibilité et développez votre
              activité. Remplissez le formulaire pour commencer.
            </Typography>
          </DialogContentText>
          <Box mt={1.5} />
          <TextField
            autoFocus
            margin="dense"
            label="Nom et prénom"
            fullWidth
            name="name"
            variant="outlined"
            size="small"
            required
            value={formState.name}
            onChange={handleFieldChange}
          />
          <Box mt={1.5} />
          <TextField
            margin="dense"
            label="Votre email"
            fullWidth
            name="email"
            type="email"
            variant="outlined"
            size="small"
            required
            value={formState.email}
            onChange={handleFieldChange}
          />
          <Box mt={1.5} />
          <TextField
            margin="dense"
            label="Votre numéro de téléphone"
            name="tel"
            type="tel"
            fullWidth
            variant="outlined"
            size="small"
            required
            value={formState.tel}
            onChange={handleFieldChange}
          />
          <Box mt={2.5} />
          <CitiesSelect
            required
            name="city"
            value={formState.city}
            onChange={(_: any, value: string) => {
              setFormState({ ...formState, city: value })
            }}
          />
          <Box mt={3} />
          <Typography mb={1}>
            Sélectionnez votre type de service : À domicile ou En atelier
          </Typography>
          <BookingTypeGroupButton
            value={formState.type}
            setValue={(value) => setFormState({ ...formState, type: value })}
          />
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="text" onClick={props.onClose}>
            Annuler
          </Button>
          <Button size="small" type="submit">
            Envoyer
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
