import React from 'react'
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { ServiceOption } from '../types'
import fullSrc from '../assets/service-options/full.png'
import exteriorSrc from '../assets/service-options/exterior-only.png'
import interiorSrc from '../assets/service-options/interior-only.png'

const options = [
  {
    label: 'Extérieur uniquement',
    value: ServiceOption.ExteriorOnly,
    icon: exteriorSrc,
  },
  {
    label: 'Intérieur uniquement"',
    value: ServiceOption.InteriorOnly,
    icon: interiorSrc,
  },
  {
    label: 'Complet',
    value: ServiceOption.Full,
    icon: fullSrc,
  },
]

interface Props {
  value: ServiceOption | null
  setValue: (value: ServiceOption) => void
}

export const ServiceOptionGroupButton = (props: Props) => {
  return (
    <ToggleButtonGroup
      orientation="vertical"
      fullWidth
      size="small"
      value={props.value}
      exclusive
      onChange={(_, value) => value && props.setValue(value as ServiceOption)}
    >
      {options.map((option) => {
        const isSelected = option.value === props.value
        return (
          <ToggleButton
            key={option.value}
            sx={{
              backgroundColor: isSelected ? `#EBF2FF !important` : null,
            }}
            value={option.value}
          >
            <Box
              py={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <img
                width={100}
                height="auto"
                src={option.icon}
                alt="type de service"
              />
              <Box mt={2} textAlign="center" textTransform="none">
                {option.label}
              </Box>
            </Box>
          </ToggleButton>
        )
      })}
    </ToggleButtonGroup>
  )
}
