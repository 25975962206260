import React from 'react'
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { BookingType } from '../types'
import { ReactComponent as AtHomeIcon } from '../assets/booking-types/at-home.svg'
import { ReactComponent as AtGarageIcon } from '../assets/booking-types/at-garage.svg'

const options = [
  {
    label: 'À domicile',
    value: BookingType.Mobile,
    icon: AtHomeIcon,
  },
  {
    label: 'Garage',
    value: BookingType.Garage,
    icon: AtGarageIcon,
  },
]

interface Props {
  value: BookingType | null
  setValue: (value: BookingType) => void
}
export const BookingTypeGroupButton = (props: Props) => {
  return (
    <ToggleButtonGroup
      fullWidth
      size="small"
      value={props.value}
      exclusive
      onChange={(_, value) => value && props.setValue(value as BookingType)}
    >
      {options.map((option) => {
        const isSelected = option.value === props.value
        return (
          <ToggleButton
            key={option.value}
            sx={{
              backgroundColor: isSelected ? `#EBF2FF !important` : null,
            }}
            value={option.value}
          >
            <Box
              py={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <option.icon />
              <Box mt={2} textTransform="none">
                {option.label}
              </Box>
            </Box>
          </ToggleButton>
        )
      })}
    </ToggleButtonGroup>
  )
}
