// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyBDp5dc6kTZ-Tcb0Ks-BIAuVsfnblD64w8',
  authDomain: 'lavagelux-a46c2.firebaseapp.com',
  projectId: 'lavagelux-a46c2',
  storageBucket: 'lavagelux-a46c2.appspot.com',
  messagingSenderId: '157958067',
  appId: '1:157958067:web:332297d0aea04dbab9d5c6',
  measurementId: 'G-9JXHEQPLBT',
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const analytics = getAnalytics(app)
