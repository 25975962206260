import { create } from 'zustand'
import { BookingType, Partner } from '../types'
import { analytics } from '../firebase'
import { logEvent } from 'firebase/analytics'

const INITIAL_FORM_STATE: Partner = {
  name: '',
  email: '',
  tel: '',
  city: '',
  type: BookingType.Mobile,
}

interface PartnerFormState {
  isDialogOpen: boolean
  formState: Partner
  setFormState: (partner: Partial<Partner>) => void
  setIsDialogOpen: (isOpen: boolean) => void
  reset: () => void
}

export const usePartnerFormState = create<PartnerFormState>((set) => ({
  isDialogOpen: false,
  formState: INITIAL_FORM_STATE,
  setFormState: (formState: Partial<Partner>) => {
    logEvent(analytics, 'partnerFormState: ' + JSON.stringify(formState))
    set((prevState) => ({
      formState: { ...prevState.formState, ...formState },
    }))
  },
  setIsDialogOpen: (isOpen: boolean) => {
    logEvent(analytics, 'isPartnerDialogOpen: ' + isOpen)
    set({
      isDialogOpen: isOpen,
    })
  },
  reset: () => {
    set(() => ({
      formState: INITIAL_FORM_STATE,
      isDialogOpen: false,
    }))
  },
}))
