import { create } from 'zustand'

interface MapStore {
  zoom: number
  setZoom: (zoom: number) => void
  reset: () => void
}

const INITIAL_STATE = {
  zoom: 14,
}

export const useMapStore = create<MapStore>((set) => ({
  zoom: INITIAL_STATE.zoom,
  setZoom: (zoom: number) => {
    set(() => ({
      zoom,
    }))
  },
  reset: () => {
    set(() => ({
      zoom: INITIAL_STATE.zoom,
    }))
  },
}))
