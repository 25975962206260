import React from 'react'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material'
import { useBookingStore } from '../stores'
import { BookingTypeGroupButton } from './booking-type-group-button'
import { ServiceTypeGroupButton } from './service-type-group-button'
import { ServiceOptionGroupButton } from './service-option-group-button'
import { DatePicker } from './date-picker'
import { TimePicker } from './time-picker'
import { ReactComponent as CalendarIcon } from '../assets/calendar.svg'
import { format } from 'date-fns'
import fr from 'date-fns/locale/fr'
import { toast } from 'react-toastify'
import { createBooking } from '../api'
import { DATE_FORMAT_API } from '../constants'

export const BookingWizard = () => {
  const step = useBookingStore((state) => state.step)
  const booking = useBookingStore((state) => state.booking)
  const nextStep = useBookingStore((state) => state.nextStep)
  const prevStep = useBookingStore((state) => state.prevStep)
  const setBooking = useBookingStore((state) => state.setBooking)
  const reset = useBookingStore((state) => state.reset)
  const [isLoading, setIsLoading] = React.useState(false)
  const handleNextStep = () => {
    nextStep()
  }

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <>
            <BookingTypeGroupButton
              value={booking.type}
              setValue={(value) => {
                setBooking({ type: value })
                nextStep()
              }}
            />
            <Box mt={2} />
            {booking.type && (
              <Button
                sx={{
                  ml: 'auto',
                  mt: 'auto',
                }}
                onClick={handleNextStep}
                fullWidth={false}
              >
                Suivant
              </Button>
            )}
          </>
        )
      case 2:
        return (
          <>
            <ServiceTypeGroupButton
              value={booking.serviceType}
              setValue={(value) => {
                setBooking({ serviceType: value })
                nextStep()
              }}
            />
            <Box mt={2} />
            {booking.serviceType && (
              <Button
                sx={{
                  ml: 'auto',
                  mt: 'auto',
                }}
                onClick={handleNextStep}
                fullWidth={false}
              >
                Suivant
              </Button>
            )}
          </>
        )
      case 3:
        return (
          <>
            <ServiceOptionGroupButton
              value={booking.serviceOption}
              setValue={(value) => {
                setBooking({ serviceOption: value })
                nextStep()
              }}
            />
            <Box mt={2} />
            {booking.serviceOption && (
              <Button
                sx={{
                  ml: 'auto',
                  mt: 'auto',
                }}
                onClick={handleNextStep}
                fullWidth={false}
              >
                Suivant
              </Button>
            )}
          </>
        )
      case 4:
        return (
          <>
            <DatePicker
              value={booking.date}
              onChange={(date) =>
                setBooking({
                  date,
                })
              }
            />
            <Box mt={2} />
            {booking.date && (
              <Button
                sx={{
                  ml: 'auto',
                  mt: 'auto',
                }}
                onClick={handleNextStep}
                fullWidth={false}
              >
                Suivant
              </Button>
            )}
          </>
        )
      case 5:
        return (
          <>
            <TimePicker
              value={booking.time}
              setValue={(time) =>
                setBooking({
                  time: time || '',
                })
              }
            />
            <Box mt={2} />
            {booking.time && (
              <Button
                sx={{
                  ml: 'auto',
                  mt: 'auto',
                }}
                onClick={handleNextStep}
                fullWidth={false}
              >
                Suivant
              </Button>
            )}
          </>
        )
      case 6:
        return (
          <>
            <TextField
              placeholder="Tapez la marque et le modèle ici"
              value={booking.vehicleInfo}
              name="info"
              size="small"
              onChange={(ev) =>
                setBooking({
                  vehicleInfo: ev.target.value,
                })
              }
            />
            <Box mt={2} />
            <TextField
              placeholder="Entrez des détails supplémentaires (facultatif)"
              multiline
              name="notes"
              size="small"
              rows={4}
              maxRows={10}
              value={booking.notes}
              onChange={(ev) =>
                setBooking({
                  notes: ev.target.value,
                })
              }
            />
            <Box mt={2} />
            <Box
              mt="auto"
              display="flex"
              justifyContent="space-between"
              width={1}
              alignItems="center"
            >
              <Box
                sx={{
                  py: 1,
                  px: 2,
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#EBF2FF',
                  borderRadius: 2,
                  flex: 1,
                  mr: 2,
                  cursor: 'pointer',
                  fontSize: 14,
                }}
                onClick={() => {
                  prevStep()
                  prevStep()
                }}
              >
                <SvgIcon color="primary">
                  <CalendarIcon />
                </SvgIcon>
                <Box ml={2}>
                  {booking.date
                    ? format(booking.date, 'EEE dd MMM', { locale: fr })
                    : ''}
                  <br />
                  {booking.time}
                </Box>
              </Box>
              {booking.vehicleInfo && (
                <Button onClick={handleNextStep} fullWidth={false}>
                  Suivant
                </Button>
              )}
            </Box>
          </>
        )
      case 7:
        return (
          <>
            <TextField
              autoFocus
              label="Nom et prénom"
              required
              name="name"
              value={booking.name}
              size="small"
              onChange={(ev) =>
                setBooking({
                  name: ev.target.value,
                })
              }
            />
            <Box mt={2} />
            <TextField
              label="Numéro de téléphone"
              required
              type="tel"
              name="phone"
              value={booking.tel}
              size="small"
              onChange={(ev) =>
                setBooking({
                  tel: ev.target.value,
                })
              }
            />
            <Box mt={2} />
            <TextField
              label="Email"
              value={booking.email}
              type="email"
              name="email"
              size="small"
              onChange={(ev) =>
                setBooking({
                  email: ev.target.value,
                })
              }
            />
            <Box mt={2} />
            <Box mt={2} />
            <Box mt="auto" display="flex" flexDirection="column" width={1}>
              <Box
                sx={{
                  py: 1,
                  px: 2,
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#EBF2FF',
                  borderRadius: 2,
                  flex: 1,
                  cursor: 'pointer',
                  fontSize: 14,
                }}
                onClick={() => {
                  prevStep()
                  prevStep()
                  prevStep()
                }}
              >
                <SvgIcon color="primary">
                  <CalendarIcon />
                </SvgIcon>
                <Box ml={2}>
                  {booking.date
                    ? format(booking.date, 'EEE dd MMM', { locale: fr })
                    : ''}
                  <br />
                  {booking.time}
                </Box>
              </Box>
              {booking.vehicleInfo && (
                <Button
                  sx={{ mt: 2 }}
                  type="submit"
                  startIcon={isLoading && <CircularProgress size={16} />}
                  disabled={isLoading}
                >
                  Réservez maintenant
                </Button>
              )}
            </Box>
          </>
        )
      case 8:
        return (
          <>
            <Alert color="success" variant="outlined" severity="success">
              <Typography mt={-0.25}>Réservation réussie</Typography>
              <Typography mt={1}>
                Merci pour votre réservation de notre service de lavage de
                voiture. Nous vérifions actuellement la disponibilité de notre
                service dans votre région. Nous vous informerons dès que
                possible.
                <br />
                Merci de votre patience.
              </Typography>
            </Alert>
            <Box mt={2} />
            <Button
              sx={{
                mt: 'auto',
              }}
              onClick={reset}
            >
              Fermer
            </Button>
          </>
        )
      default:
        return null
    }
  }

  const getStepTitle = (step: number) => {
    switch (step) {
      case 1:
        return 'Sélectionnez le service à domicile ou en garage'
      case 2:
        return 'Sélectionnez le service'
      case 3:
        return 'Sélectionnez le service'
      case 4:
        return 'Sélectionner la date'
      case 5:
        return "Sélectionner l'heure"
      case 6:
        return 'Entrer la marque et le modèle'
      case 7:
        return 'Vos coordonnées'
      default:
        return ''
    }
  }

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      setIsLoading(true)
      const newBooking = {
        name: booking.name,
        email: booking.email,
        tel: booking.tel,
        location: JSON.stringify(booking.location),
        type: booking.type,
        serviceOption: booking.serviceOption,
        serviceType: booking.serviceType,
        vehicleInfo: booking.vehicleInfo,
        notes: booking.notes,
        date: booking.date ? format(booking.date, DATE_FORMAT_API) : '',
        time: booking.time,
      }
      await createBooking(newBooking)
      nextStep()
    } catch (error) {
      console.error('Failed to create partner:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box p={3} flex={1} display="flex" flexDirection="column">
      <Typography variant="h5" fontWeight={500}>
        {getStepTitle(step)}
      </Typography>
      <Box mt={4} />
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        height="100%"
        component="form"
        onSubmit={handleSubmit}
      >
        {renderForm()}
      </Box>
    </Box>
  )
}
