import React from 'react'
import '@fontsource/open-sans/300.css'
import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/500.css'
import '@fontsource/open-sans/700.css'
import 'react-toastify/dist/ReactToastify.css'
import GoogleMapReact from 'google-map-react'
import {
  Avatar,
  Box,
  Button,
  Drawer,
  AppBarProps as MuiAppBarProps,
  styled,
  IconButton,
} from '@mui/material'
import PlaceIcon from '@mui/icons-material/Place'
import {
  GoogleMapsPlacesSelect,
  AppBar as AppBarComponent,
  BecomePartnerDialog,
  BookingWizard,
} from './components'
import { ThemeProvider } from './providers'
import logoSrc from './assets/logo.png'
import { ToastContainer } from 'react-toastify'
import { useBookingStore, useMapStore, usePartnerFormState } from './stores'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import BookingProgressBar from './components/booking-progress-bar'
import {
  GOOGLE_MAPS_API_KEY,
  MAP_STYLES,
  CASABLANCA_COORDINATES,
} from './constants'
import { analytics } from './firebase'
import { logEvent } from 'firebase/analytics'

const drawerWidth = 400

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(AppBarComponent, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}))

export default function App() {
  const { isDialogOpen, setIsDialogOpen } = usePartnerFormState()
  const {
    booking,
    setBooking,
    prevStep,
    step,
    setIsBookingFormOpen,
    isBookingFormOpen,
    reset,
  } = useBookingStore()
  const { zoom } = useMapStore()

  React.useEffect(() => {
    const setVhProperty = () => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    // Set the property initially
    setVhProperty()

    // Set the property when the window is resized
    window.addEventListener('resize', setVhProperty)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', setVhProperty)
    }
  }, [])

  React.useEffect(() => {
    logEvent(analytics, 'visit')
  }, [])

  return (
    <ThemeProvider>
      <AppBar />
      <Box sx={{ display: 'flex' }}>
        <Main open={isBookingFormOpen}>
          <ToastContainer />
          <BecomePartnerDialog
            onClose={() => setIsDialogOpen(false)}
            isOpen={isDialogOpen}
          />
          <div
            style={{
              height: 'calc(100 * var(--vh) - 72px)',
              width: '100%',
              position: 'relative',
            }}
          >
            <GoogleMapReact
              defaultZoom={12}
              zoom={zoom}
              defaultCenter={CASABLANCA_COORDINATES}
              center={booking.location?.coordinates || CASABLANCA_COORDINATES}
              bootstrapURLKeys={{
                key: GOOGLE_MAPS_API_KEY,
                libraries: ['places'],
              }}
              options={{ styles: MAP_STYLES }}
            >
              <PlaceIcon
                color="primary"
                sx={{
                  fontSize: '60px',
                  mt: '-30px',
                  ml: '-30px',
                }}
              />
            </GoogleMapReact>
            <Box
              sx={{
                backgroundImage:
                  'linear-gradient(180deg,#fff,#fff 25%,hsla(0,0%,100%,0))',
              }}
              width={1}
              position="absolute"
              top={-1}
              left={0}
              right={0}
              zIndex={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Avatar
                alt="Lavage Lux"
                sx={{ width: 100, height: 100 }}
                src={logoSrc}
              />
              <Box width={1} mt={4} display="flex" justifyContent="center">
                <GoogleMapsPlacesSelect
                  onChange={(coordinates, address) => {
                    setBooking({
                      location: {
                        address,
                        coordinates,
                      },
                    })
                  }}
                />
              </Box>
            </Box>
            {booking.location && (
              <Box
                display="flex"
                justifyContent="center"
                position="absolute"
                bottom={72}
                left={0}
                right={0}
                zIndex={1}
              >
                <Button
                  sx={{
                    maxWidth: 380,
                    borderRadius: 2,
                    fontSize: 18,
                    fontWeight: 500,
                    lineHeight: 1.5,
                  }}
                  onClick={() => setIsBookingFormOpen(true)}
                >
                  Suivant
                </Button>
              </Box>
            )}
          </div>
        </Main>
        <Drawer
          sx={{
            width: drawerWidth,
            maxWidth: '100%',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              maxWidth: '100%',
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={isBookingFormOpen}
        >
          <DrawerHeader>
            <IconButton
              onClick={() => {
                if (step > 1) prevStep()
                else {
                  setIsBookingFormOpen(false)
                  reset()
                }
              }}
              color="primary"
            >
              <ChevronLeftIcon fontSize="large" />
            </IconButton>
          </DrawerHeader>
          <BookingProgressBar />
          <BookingWizard />
        </Drawer>
      </Box>
    </ThemeProvider>
  )
}
