import React from 'react'
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { BookingType } from '../types'

interface Props {
  value: string
  setValue: (value: string) => void
}

const options = [
  {
    label: '08:00',
    value: '08:00',
  },
  {
    label: '09:00',
    value: '09:00',
  },
  {
    label: '10:00',
    value: '10:00',
  },
  {
    label: '12:00',
    value: '12:00',
  },
  {
    label: '13:30',
    value: '13:30',
  },
  {
    label: '16:00',
    value: '16:00',
  },
]

interface Props {
  value: string
  setValue: (value: string) => void
}
export const TimePicker = (props: Props) => {
  return (
    <ToggleButtonGroup
      fullWidth
      size="small"
      value={props.value}
      exclusive
      onChange={(_, value) => value && props.setValue(value as BookingType)}
    >
      {options.map((option) => {
        const isSelected = option.value === props.value
        return (
          <ToggleButton
            key={option.value}
            sx={{
              backgroundColor: isSelected ? `#EBF2FF !important` : null,
            }}
            value={option.value}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box textTransform="none">{option.label}</Box>
            </Box>
          </ToggleButton>
        )
      })}
    </ToggleButtonGroup>
  )
}
