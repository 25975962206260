import React from 'react'
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import { Button, SvgIcon } from '@mui/material'
import { ReactComponent as TopBarIcon } from '../assets/top-bar-icon.svg'
import { usePartnerFormState } from '../stores'
import { toast } from 'react-toastify'

export function AppBar() {
  const { setIsDialogOpen } = usePartnerFormState()
  return (
    <Box sx={{ flexGrow: 1 }}>
      <MuiAppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="primary"
            sx={{ mr: 2 }}
            onClick={() => {
              toast(
                'Nous travaillons à ajouter de nouvelles fonctionnalités incroyables. Elles seront disponibles dès que possible. Merci pour votre patience!',
                {
                  position: 'top-center',
                  closeOnClick: true,
                  pauseOnHover: true,
                  theme: 'colored',
                }
              )
            }}
          >
            <SvgIcon sx={{ fontSize: 30 }}>
              <TopBarIcon />
            </SvgIcon>
          </IconButton>
          <Button
            sx={{ width: 220, p: 1, ml: 'auto', textTransform: 'none' }}
            variant="outlined"
            color="primary"
            onClick={() => setIsDialogOpen(true)}
          >
            Devenir un partenaire
          </Button>
        </Toolbar>
      </MuiAppBar>
    </Box>
  )
}
