import React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import parse from 'autosuggest-highlight/parse'
import { debounce } from '@mui/material/utils'
import { IconButton, InputAdornment } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { useBookingStore, useMapStore } from '../stores'

const autocompleteService = { current: null }

interface MainTextMatchedSubstrings {
  offset: number
  length: number
}
interface StructuredFormatting {
  main_text: string
  secondary_text: string
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[]
}
interface PlaceType {
  description: string
  structured_formatting: StructuredFormatting
  longitude?: number
  latitude?: number
}

interface Props {
  onChange: (coordinates: { lng: number; lat: number }, address: string) => void
}

export const GoogleMapsPlacesSelect = (props: Props) => {
  const { setZoom } = useMapStore()
  const { setBooking } = useBookingStore()
  const [value, setValue] = React.useState<PlaceType | null>(null)
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<readonly PlaceType[]>([])
  const [googleReady, setGoogleReady] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const dummyRef = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    const interval = setInterval(() => {
      if ((window as any).google) {
        setGoogleReady(true)
        clearInterval(interval)
      }
    }, 100) // checks every 100ms

    return () => {
      clearInterval(interval)
    }
  }, [])

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          ;(autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          )
        },
        400
      ),
    []
  )

  const getGeocode = (place: PlaceType) => {
    const geocoder = new (window as any).google.maps.Geocoder()
    geocoder.geocode(
      { address: place.description },
      // @ts-ignore
      function (results, status) {
        if (status === 'OK') {
          const lat = results[0].geometry.location.lat()
          const lng = results[0].geometry.location.lng()
          // Notify parent component
          props.onChange({ lat, lng }, place.description)
          setZoom(17)
        }
      }
    )
  }

  React.useEffect(() => {
    if (!googleReady) return

    let active = true

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch(
      // @ts-ignore
      { input: inputValue, componentRestrictions: { country: 'MA' } },
      (results?: readonly PlaceType[]) => {
        if (active) {
          let newOptions: readonly PlaceType[] = []

          if (value) {
            newOptions = [value]
          }

          if (results) {
            newOptions = [...newOptions, ...results]
          }
          setOptions(newOptions)
        }
      }
    )

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  if (!googleReady) return null
  return (
    <>
      <div ref={dummyRef}></div>

      <Autocomplete
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        sx={{
          maxWidth: 400,
          width: '100%',
          '& .MuiAutocomplete-inputRoot': {
            paddingRight: '16px !important',
          },
        }}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        blurOnSelect="touch"
        filterSelectedOptions
        value={value}
        noOptionsText="Aucun endroit trouvé"
        onChange={(event: any, newValue: PlaceType | null) => {
          setOptions(newValue ? [newValue, ...options] : options)
          setValue(newValue)
          if (newValue) {
            getGeocode(newValue)
          }
          if (inputRef.current && dummyRef.current) {
            dummyRef.current.focus()
            inputRef.current.blur()
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        clearIcon={<></>}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={inputRef}
            sx={{
              border: 'none',
              boxShadow: '0 0 0 1px #ebebed,0 6px 24px 0 rgba(0,0,0,.1)',
              paddingTop: 1.5,
              paddingBottom: 1.5,
              borderRadius: '16px',
              background: '#fff',
            }}
            placeholder="Veuillez entrer votre adresse domicile"
            fullWidth
            variant="standard"
            inputProps={{
              ...params.inputProps,
              style: {
                paddingLeft: 8,
              },
            }}
            InputProps={{
              ...params.InputProps,

              disableUnderline: true,
              startAdornment: (
                <SearchIcon
                  sx={{
                    fontSize: 30,
                    ml: 1.5,
                  }}
                  color="primary"
                />
              ),
              endAdornment: value && (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ p: 0, m: 0 }}
                    onClick={() => {
                      setValue(null)
                      setInputValue('')
                      setBooking({
                        location: null,
                      })
                      setZoom(14)
                    }}
                  >
                    <CloseIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings || []

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [
              match.offset,
              match.offset + match.length,
            ])
          )

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
                >
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          )
        }}
      />
    </>
  )
}
