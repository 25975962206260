export const GOOGLE_MAPS_API_KEY = 'AIzaSyCVhlfhUIJb28v9ywcDQRrL5z_OAHiMiVw'
export const MAP_STYLES = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  // {
  //   elementType: 'labels',
  //   stylers: [
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
  // {
  //   elementType: 'labels.icon',
  //   stylers: [
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  // {
  //   featureType: 'administrative',
  //   elementType: 'geometry',
  //   stylers: [
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'administrative.land_parcel',
  //   stylers: [
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  // {
  //   featureType: 'administrative.neighborhood',
  //   stylers: [
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
  {
    featureType: 'landscape',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    stylers: [
      {
        color: '#f1fff0',
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    stylers: [
      {
        color: '#e7ebef',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#e7ebef',
      },
    ],
  },
  // {
  //   featureType: 'road',
  //   elementType: 'labels.icon',
  //   stylers: [
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
  {
    featureType: 'road',
    elementType: 'labels.text',
    stylers: [
      {
        color: '#d4d4d4',
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#d4d4d4',
      },
      {
        visibility: 'on',
      },
    ],
  },
  // {
  //   featureType: 'road',
  //   elementType: 'labels.text.stroke',
  //   stylers: [
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#e7ebef',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#3d3d3d',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.local',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#e7ebef',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels',
    stylers: [
      {
        color: '#e7ebef',
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f2f7ff',
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
]
