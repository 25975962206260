import React from 'react'
import { LinearProgress } from '@mui/material'
import { useBookingStore } from '../stores'

const totalSteps = 8

export default function BookingProgressBar() {
  const step = useBookingStore((state) => state.step)

  const progress = (step / totalSteps) * 100

  return <LinearProgress variant="determinate" value={progress} />
}
