import { Autocomplete, TextField } from '@mui/material'
import cities from '../assets/cities.json'

export const CitiesSelect = (props: any) => {
  const options = cities.map((city) => ({
    label: city.ville,
    value: city.id,
  }))

  return (
    <Autocomplete
      {...props}
      autoHighlight
      options={options}
      isOptionEqualToValue={(option: any, value: any) =>
        option.value === value.value
      }
      noOptionsText="Aucune ville correspondante trouvée"
      renderInput={(params) => (
        <TextField
          {...params}
          required
          fullWidth
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
            form: {
              autoComplete: 'off',
            },
          }}
          size="small"
          label="Ville"
        />
      )}
    />
  )
}
