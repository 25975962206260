import React from 'react'
import { createTheme, ThemeProvider as Provider } from '@mui/material/styles'

const headingStyle = {
  lineHeight: 1.1,
  fontWeight: 300,
}

export const theme = createTheme({
  palette: {
    background: {
      default: '#fff',
    },
    primary: {
      main: '#4483EF',
      // dark: '##4483EF',
      // light: '##4483EF',
    },
    secondary: {
      main: '#1DA853',
    },
    warning: {
      main: '#EC6136',
    },
  },
  typography: {
    fontFamily: 'Open Sans',
    h1: headingStyle,
    h2: headingStyle,
    h3: headingStyle,
    h4: headingStyle,
    h5: headingStyle,
    h6: headingStyle,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        h1: headingStyle,
        h2: headingStyle,
        h3: headingStyle,
        h4: headingStyle,
        h5: headingStyle,
        h6: headingStyle,
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          '.MuiToolbar-root': {
            minHeight: '72px',
            background: '#fff',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFormControl-root-MuiTextField-root': {
            backgroundColor: '#fff',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'primary',
        fullWidth: true,
      },
      styleOverrides: {
        contained: {
          padding: '12px',
          borderRadius: '6px',
          textTransform: 'unset',
          fontWeight: 400,
          fontSize: 15,
          letterSpacing: 1.5,
          color: '#F5F0E3',
        },
        text: {
          padding: '12px',
          borderRadius: '6px',
          textTransform: 'unset',
          fontWeight: 400,
          fontSize: 15,
          letterSpacing: 1.5,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        button: {
          color: '#939088',
          borderBottomColor: '#939088',
          fontSize: 16,
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {},
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '& img, & svg': {
            transition: 'transform 0.3s ease',
          },
          '&:hover img, &:hover svg': {
            transform: 'scale(1.1)',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        outlinedWarning: {
          backgroundColor: '#f5f0e3',
          color: '#EC6136',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#B8B4AA',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: 16,
          justifyContent: 'center',
          fontSize: 18,
        },
        divider: {
          borderBottomColor: '#B8B4AA',
        },
      },
    },
  },
})

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  return <Provider theme={theme}>{children}</Provider>
}
