import { Partner } from '../types'

export async function createPartner(partner: Partner) {
  const response = await fetch(
    'https://us-central1-lavagelux-a46c2.cloudfunctions.net/api/api/partners',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(partner),
    }
  )

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`)
  }

  return response.json()
}
