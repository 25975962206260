import { create } from 'zustand'
import { Booking } from '../types'
import { analytics } from '../firebase'
import { logEvent } from 'firebase/analytics'

interface BookingStore {
  isBookingFormOpen: boolean
  step: number
  booking: Booking
  setBooking: (booking: Partial<Booking>) => void
  setIsBookingFormOpen: (isBookingFormOpen: boolean) => void
  nextStep: () => void
  prevStep: () => void
  reset: () => void
}

const INITIAL_STATE: Booking = {
  type: null,
  name: '',
  tel: '',
  email: '',
  vehicleInfo: '',
  serviceType: null,
  serviceOption: null,
  notes: '',
  date: new Date(),
  time: '',
  location: null,
}

export const useBookingStore = create<BookingStore>((set) => ({
  step: 1,
  isBookingFormOpen: false,
  booking: INITIAL_STATE,
  setIsBookingFormOpen: (isBookingFormOpen: boolean) => {
    logEvent(analytics, 'isBookingFormOpen: ' + isBookingFormOpen)
    set({
      isBookingFormOpen,
    })
  },
  setBooking: (booking: Partial<Booking>) => {
    logEvent(analytics, 'booking: ' + JSON.stringify(booking))
    set((prevState) => ({
      booking: {
        ...prevState.booking,
        ...booking,
      },
    }))
  },
  nextStep: () => set((state) => ({ step: state.step + 1 })),
  prevStep: () => set((state) => ({ step: state.step - 1 })),
  reset: () => {
    set(() => ({
      booking: INITIAL_STATE,
      isBookingFormOpen: false,
      step: 1,
    }))
  },
}))
