import frLocale from 'date-fns/locale/fr'
import {
  LocalizationProvider,
  MobileDatePickerProps,
  StaticDatePicker,
} from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

export const DatePicker = (
  props: MobileDatePickerProps<Date> & {
    id?: string
  }
) => {
  return (
    <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        {...props}
        disablePast
        slots={{
          actionBar: () => <></>,
          toolbar: () => <></>,
        }}
      />
    </LocalizationProvider>
  )
}
